export function numberWithCommas(x) {
  if (!x) return;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export const COUNTRIES = [
  'ARGENTINA',
  'BOLIVIA',
  'BRASIL',
  'BULGARIA',
  'CAMBOYA',
  'CANADÁ',
  'CHILE',
  'COLOMBIA',
  'COSTA RICA',
  'ECUADOR',
  'EL SALVADOR',
  'ESLOVAQUIA',
  'ESTADOS UNIDOS',
  'FILIPINAS',
  'GEORGIA',
  'GUATEMALA',
  'HAITÍ',
  'HONDURAS',
  'INDIA',
  'INDONESIA',
  'JAMAICA',
  'KENIA',
  'MADAGASCAR',
  'MARRUECOS',
  'MEXICO',
  'NIGERIA',
  'PANAMÁ',
  'PARAGUAY',
  'PERÚ',
  'PORTUGAL',
  'REINO UNIDO',
  'REPÚBLICA DOMINICANA',
  'RUANDA',
  'RUMANÍA',
  'SENEGAL',
  'SIERRA LEONA',
  'SINGAPUR',
  'SUDÁFRICA',
  'TANZANIA',
  'UCRANIA',
  'UGANDA',
  'URUGUAY',
  'UZBEKISTÁN',
];

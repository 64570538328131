import React from 'react';

import './Indicators.css';

export const Indicators = () => {
  return (
    <div className="demo-box p-4">
      <div className="text-center" style={{ position: 'absolute' }}>
        <h4 className="demo-title fw-bold mb-2">Toma el demo de tu Semáforo</h4>

        <a
          href="https://demo.povertystoplight.org"
          className="btn btn-success-custom mt-1"
        >
          Ingresa aquí
        </a>
      </div>
    </div>
  );
};

import React from 'react';
import './PageNotFound.css';

export const PageNotFoundScreen = () => {
    return (
        <div className='error__wrapper'>
            <h1>404</h1>
            <h2>Página no encontrada</h2>
            <p>No se encuentra la página que estás buscando :(</p>
        </div>
    );
}

import React from 'react';
import 'animate.css';

import './search.css';
import { Indicators } from '../indicators/Indicators';

export const Search = () => {
  return (
    <section className="bg-white py-5">
      <div className="row justify-content-between">
        <div className="col-lg-8  mb-5 col-xs-12 no-padding">
          <div className="fixedPopUp animate__animated animate__fadeInLeft">
            <div className="imgContainer">
              <img
                className="popupImage"
                src="https://a.storyblok.com/f/42340/625x1000/6362ec1d51/whoownspoverty.jpg"
                alt=""
              />
            </div>
            <div className="otherTextPopContainer">
              <span className="titlePop">¿Quién es dueño de la pobreza?</span>
              <br></br>
              <span className="buttontitlePop">de Martin Burt</span>

              <div className="buttonLower">
                <a
                  href="https://www.povertystoplight.org/es/who-owns-poverty"
                  className="popContainerStuff"
                >
                  <div className="subtitlePop">LEER MÁS</div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-xs-12 no-padding">
          <Indicators />
        </div>
      </div>
    </section>
  );
};

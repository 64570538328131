import React from 'react';
import './AboutStoplight.css';
import { Buttons } from './buttons/Buttons';

export const AboutStoplightScreen = () => {
  return (
    <div className="uc__wrapper overflow-x-hidden">
      <h2 className="text-center">BASE DE DATOS CENTRAL</h2>
      <h4 className="text-center">
        Datos y Estadísticas del Semáforo de Eliminación de Pobreza
      </h4>
      <Buttons />
    </div>
  );
};

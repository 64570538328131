import { Route, Routes, BrowserRouter } from 'react-router-dom';
// import { HomeScreen } from '../components/home/HomeScreen';
import { Navbar } from '../shared/Navbar';
import { Footer } from '../shared/Footer';
import { PageNotFoundScreen } from '../components/404/PageNotFoundScreen';
import { UnderConstructionScreen } from '../components/under-construction/UnderConstructionScreen';
import { AboutStoplightScreen } from '../components/about-stoplight/AboutStoplightScreen';

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <>
        <Navbar />
        <div>
          <Routes>
            <Route path="/" element={<AboutStoplightScreen />} />
            <Route
              path="/under-construction"
              element={<UnderConstructionScreen />}
            />
            <Route path="*" element={<PageNotFoundScreen />} />
          </Routes>
        </div>
        <Footer />
      </>
    </BrowserRouter>
  );
};

import React from 'react';

import './Footer.css';

import fbIcon from './../images/fb-icon.png';
import inIcon from './../images/in-icon.png';
import mdIcon from './../images/md-icon.png';
import twIcon from './../images/tw-icon.png';
import ytIcon from './../images/yt-icon.png';
// import fpLogo from './../images/logo_fp.png'

import { ReactComponent as Instagram } from './../images/instagram_icon.svg';

export const Footer = () => {
  return (
    <footer className="footer overflow-x-hidden">
      <div className="bubble bubble-yellow"></div>
      <div className="bubble bubble-green"></div>
      <div className="bubble bubble-red"></div>

      <div className="bubble bubble-yellow-1"></div>
      <div className="bubble bubble-green-1"></div>
      <div className="bubble bubble-red-1"></div>

      <div className="wrapper">
        <div className="footer__follow">
          <div className="footer__title">SÍGUENOS</div>
          <a
            href="https://www.facebook.com/povertystoplight/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={fbIcon} alt="" className="footer__icon fbIcon-border" />
          </a>
          <a
            href="https://www.linkedin.com/company/poverty-stoplight/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={inIcon} alt="" className="footer__icon inIcon-border" />
          </a>
          <a
            href="https://medium.com/@PStoplight"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={mdIcon} alt="" className="footer__icon mdIcon-border" />
          </a>
          <a
            href="https://twitter.com/pstoplight?lang=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twIcon} alt="" className="footer__icon twIcon-border" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCiW7A3WT7OTaLvGWuIt3nIw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ytIcon} alt="" className="footer__icon ytIcon-border" />
          </a>
          <a
            href="https://www.instagram.com/povertystoplight/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram alt="" className="footer__icon instagram-border" />
          </a>
        </div>
      </div>
      {/*  */}
      <div className="wrapper">
        <div className="line"></div>
      </div>
      {/*  */}
      <div className="wrapper">
        <div className="footer__content">
          <div className="footer__links">
            <div className="footer__title">QUÉ HACEMOS</div>
            <a
              className="footer__link"
              href="https://www.povertystoplight.org/es/what-it-is"
            >
              <div>Qué es</div>
            </a>
            <a
              className="footer__link"
              href="https://www.povertystoplight.org/es/how-it-works"
            >
              <div>Cómo funciona</div>
            </a>
            <a
              className="footer__link"
              href="https://www.povertystoplight.org/es/impact"
            >
              <div>Impacto</div>
            </a>
            <a
              className="footer__link"
              href="https://www.povertystoplight.org/es/faq"
            >
              <div>Preguntas Frecuentes</div>
            </a>
          </div>
          <div className="footer__links">
            <div className="footer__title">QUIÉNES SOMOS</div>
            <a
              className="footer__link"
              href="https://www.povertystoplight.org/es/about"
            >
              <div>Nuestro Equipo</div>
            </a>
            <a
              className="footer__link"
              href="https://www.povertystoplight.org/es/where-we-work"
            >
              <div>Dónde trabajamos</div>
            </a>
            <a
              className="footer__link"
              href="https://www.povertystoplight.org/es/partners"
            >
              <div>Nuestros Aliados</div>
            </a>
          </div>
          <div className="footer__links">
            <div className="footer__title">RECURSOS</div>
            <a
              className="footer__link"
              href="https://www.povertystoplight.org/es/news"
            >
              <div>En las noticias</div>
            </a>
            <a
              className="footer__link"
              href="https://www.povertystoplight.org/es/content"
            >
              <div>Contenido</div>
            </a>
            <a
              className="footer__link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://medium.com/@PStoplight"
            >
              <div>Nuestro Blog</div>
            </a>
          </div>
          <div className="footer__contacts">
            <div className="footer__title">MANTENTE EN CONTACTO</div>
            <p className="footer__paragraph">
              <span className="icon-phone"></span>
              <span className="footer__phone"> (595) 21 609 277</span>
              <span className="footer__phone footer__title">
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </span>
              <a href="mailto:info@povertystoplight.org">
                <span className="icon-mail"></span>
              </a>
              <br />
            </p>
            <div className="footer__paragraph rich-text">
              <p>
                Fundación Paraguaya <br />
                Manuel Blinder 5589 <br />
                Asunción, Paraguay
              </p>
            </div>
            <div className="footer__title">IMPULSADO POR</div>
            <img
              src="https://www.povertystoplight.org/static/logo_fp.9859b60c.png"
              alt=""
              className="fpLogo"
            />
          </div>
        </div>
      </div>
      {/*  */}
      <div className="wrapper">
        <div className="footer__meta">
          <p className="footer__copyright">
            © Copyright {new Date().getFullYear()} — Fundación Paraguaya
          </p>
          {/* <p className="footer__bragging">
                        Made with love by<a href="http://penguin.digital">Penguin Digital</a>
                    </p> */}
        </div>
      </div>
    </footer>
  );
};

import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { numberWithCommas } from '../../../helpers/utils';

export function DonutChart({
  totalGreen,
  totalYellow,
  totalRed,
  totalSkipped,
  totalDoesNotApply,
}) {
  const state = {
    series: [
      totalRed,
      totalYellow,
      totalGreen,
      totalSkipped,
      totalDoesNotApply,
    ],
    options: {
      labels: ['Rojos', 'Amarillos', 'Verdes', 'Omitido', 'No aplica'],
      colors: ['#E1504D', '#F0CB17', '#4CA948', '#E1DDD8', '#989898'],
      legend: {
        show: false,
      },
      chart: {
        width: '600px',
        type: 'donut',
        fontFamily: 'Poppins, Arial, sans-serif',
        selection: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value, opts) =>
          numberWithCommas(opts.w.globals.series[opts.seriesIndex]) +
          ' | ' +
          Math.round(value) +
          ' %',
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '16px',
          fontFamily: 'Poppins, Arial, sans-serif',
          fontWeight: '400',
          colors: ['#ffffff'],
        },
        background: {
          enabled: true,
          foreColor: '#212121',
          padding: 20,
          borderRadius: 6,
          borderWidth: 0,
          borderColor: '#fff',
          opacity: 1,
        },
        dropShadow: {
          enabled: false,
        },
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <ReactApexChart
      options={state.options}
      series={state.series}
      type="donut"
      width={450}
    />
  );
}

import React from 'react';
import { Link } from 'react-router-dom';

import './Navbar.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

export const Navbar = () => {
  return (
    <header className="relative flex justify-between overflow-visible bg-white">
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container-fluid">
          <div className="navbar-header flex items-center px-3">
            <Link to="/">
              <img
                src="//a.storyblok.com/f/42340/2768x1072/b6547c59e2/ps_es_logo_cropped.png"
                alt=""
                className="p-2 h-22"
              />
            </Link>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse border-gray-300"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-auto ">
              <li className="nav-item dropdown ">
                <a
                  className="nav-link dropdown-toggle "
                  href="#!"
                  onClick={e => e.preventDefault()}
                >
                  Qué hacemos
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.povertystoplight.org/es/what-it-is"
                    >
                      Qué es
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.povertystoplight.org/es/how-it-works"
                    >
                      Cómo funciona
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.povertystoplight.org/es/impact"
                    >
                      Impacto
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.povertystoplight.org/es/faq"
                    >
                      Preguntas frecuentes
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown ">
                <a
                  className="nav-link dropdown-toggle "
                  href="#!"
                  onClick={e => e.preventDefault()}
                >
                  Quiénes somos
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.povertystoplight.org/es/about"
                    >
                      Nuestro Equipo
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.povertystoplight.org/es/where-we-work"
                    >
                      Nuestros Socios
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.povertystoplight.org/es/partners"
                    >
                      Nuestros Aliados
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown ">
                <a
                  className="nav-link dropdown-toggle "
                  href="#!"
                  onClick={e => e.preventDefault()}
                >
                  Recursos
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.povertystoplight.org/es/news"
                    >
                      En las noticias
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.povertystoplight.org/es/content"
                    >
                      Publicaciones
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.povertystoplight.org/es/blog"
                    >
                      Nuestro Blog
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.povertystoplight.org/es/webinars"
                    >
                      Webinars y Eventos
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown ">
                <a
                  className="nav-link dropdown-toggle "
                  href="#!"
                  onClick={e => e.preventDefault()}
                >
                  ¿Quién es dueño de la pobreza?
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.povertystoplight.org/es/who-owns-poverty"
                    >
                      El Libro
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.amazon.com/gp/product/1912157209/ref=dbs_a_def_rwt_bibl_vppi_i1"
                    >
                      Comprar el Libro
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://a.storyblok.com/f/42340/x/b10cc53b6d/prof-burt-_who-owns-poverty__-syllabus-27-nov-2019.pdf"
                    >
                      Curriculum
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.povertystoplight.org/es/lesson-plans"
                    >
                      Planes de Clase
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.povertystoplight.org/es/endorsements"
                    >
                      Agradecimientos
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.povertystoplight.org/es/acknowledgments"
                    >
                      Avales
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.povertystoplight.org/es/about-the-author"
                    >
                      Sobre el autor
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown ">
                <a
                  className="nav-link dropdown-toggle "
                  href="#!"
                  onClick={e => e.preventDefault()}
                >
                  Nuestros Semáforos
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://education.povertystoplight.org/"
                    >
                      Semáforo Educativo
                    </a>
                  </li>
                </ul>
              </li>
            </ul>

            <div className="d-flex bg-green-500 text-white px-5 py-3 text-center">
              <span className="py-3 fs-5">
                <FontAwesomeIcon icon={faLock} className="me-2" />
                <a
                  className="pointer text-white"
                  style={{ textDecoration: 'none' }}
                  href="https://www.povertystoplight.org/es"
                >
                  LOG IN
                </a>
              </span>
            </div>
          </div>
        </div>
      </nav>

      <nav className="navbar navbar-expand-lg navbar-dark bg-green-500">
        <div className="container-fluid px-5">
          <h3 className="navbar-brand fw-bold" style={{ marginTop: '7px' }}>
            Base de datos central del Semáforo
          </h3>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    `nav-link ${isActive ? 'active' : ''}`
                  }
                  to="/"
                >
                  Sobre el Semáforo
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    `nav-link ${isActive ? 'active' : ''}`
                  }
                  to="/under-construction"
                >
                  Glosario de términos
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    `nav-link ${isActive ? 'active' : ''}`
                  }
                  to="/under-construction"
                >
                  Comparabilidad
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    `nav-link ${isActive ? 'active' : ''}`
                  }
                  to="/under-construction"
                >
                  Contacto
                </NavLink>
              </li>
            </ul>
          </div> */}
        </div>
      </nav>
    </header>
  );
};

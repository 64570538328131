import React, { useRef, useEffect } from 'react';
import './Tableau.css';

const { tableau } = window;

/**
 * @param { { URL: string } } props
 */
export function Tableau(props) {
  const ref = useRef(null);

  useEffect(() => {
    const options = {
      onFirstInteractive: function () {
        // The viz is now ready and can be safely used.
        document.getElementById('tableau').style.height = null;
        const vizDiv = document
          .getElementById('tableau')
          .getElementsByTagName('iframe');

        if (vizDiv[0].offsetWidth < 1024) {
          vizDiv[0].setAttribute('scrolling', 'yes');
        }
      },
    };

    function initViz() {
      new tableau.Viz(ref.current, props.URL, options);
    }

    initViz();
  }, [props.URL]);

  return <div id="tableau" ref={ref} style={{ height: '500px' }}></div>;
}

import React from 'react'

import './Spinner.css'

export const Spinner = ( { visible= true, width = '80px' , height = '80px'}) => {

    return (
        <>
            {
                visible && <div className='spinner' style={{ width: width, height: height }} />
            }
        </>
    )
}

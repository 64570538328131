import React from 'react'
import './UnderConstruction.css'

export const UnderConstructionScreen = () => {
    return (
        <div className='uc__wrapper'>
        <h2>Página en construcción</h2>
        <p>Esta página está en proceso ¡Gracias por tu visita!</p>
    </div>
    )
}

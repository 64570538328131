import React from 'react';
import { Tableau } from '../about-stoplight/tableau/Tableau';
import { Perspective } from './perspective/Perspective';
import { Search } from './search/Search';

export function HomeScreen(props) {
  return (
    <div>
      <Perspective />
      <Tableau URL={props.URL} />

      <Search />
    </div>
  );
}

import React from 'react';
import './Buttons.css';
import imgHome from './../../../images/stoplight-icon-buttons/home.svg';
import imgPeopleHouse from './../../../images/stoplight-icon-buttons/people.svg';
import imgStopLight from './../../../images/stoplight-icon-buttons/stoplight.svg';
import imgHomeActive from './../../../images/stoplight-icon-buttons/home-white.svg';
import imgPeopleHouseActive from './../../../images/stoplight-icon-buttons/people-white.svg';
import imgStopLightActive from './../../../images/stoplight-icon-buttons/stoplight-white.svg';

import { HomeScreen } from '../../home/HomeScreen';
import { useState } from 'react';
import { Tableau } from '../tableau/Tableau';

export const tableauHomeMapDataURL =
  'https://public.tableau.com/views/Inicio_16541108169160/Inicio?:language=en-US&:display_count=n&:origin=viz_share_link&:size=1400,1400';
export const tableauHouseDataURL =
  'https://public.tableau.com/views/DatosHogar/DatosdelHogar?:language=en-US&:display_count=n&:origin=viz_share_link&:size=1400,1400';
export const tableauHouseIncomeDataURL =
  'https://public.tableau.com/views/IngresosHogar/IngresosHogar?:language=en-US&:display_count=n&:origin=viz_share_link&:size=1400,1400';
export const tableauStoplightDataURL =
  'https://public.tableau.com/views/Semaforo_16541122253140/Semforo?:language=en-US&:display_count=n&:origin=viz_share_link&:size=1400,1400';

export const Buttons = () => {
  const components = {
    home: { componente: HomeScreen, url: tableauHomeMapDataURL },
    tableauHouseData: { componente: Tableau, url: tableauHouseDataURL },
    tableauHouseIncomeData: {
      componente: Tableau,
      url: tableauHouseIncomeDataURL,
    },
    tableauStoplightData: { componente: Tableau, url: tableauStoplightDataURL },
  };

  function DynamicComponent(component) {
    let dataPage = components[component.user];
    const SelectUser = dataPage.componente;
    let url = dataPage.url;
    return <SelectUser URL={url} />;
  }

  const [user, changeComponent] = useState('home');

  return (
    <div>
      <div className="container">
        <div className="row text-center" style={{ justifyContent: 'center' }}>
          {user === 'home' ? (
            <div
              className="col-md-2 col-xs-2 col-lg-2 button-img-activo"
              id="home"
            >
              <img src={imgHomeActive} className="img-fluid" alt="Inicio " />
              <br></br>
              <span className="fuente">Inicio</span>
            </div>
          ) : (
            <div
              className="col-md-2 col-xs-2 col-lg-2 button-img"
              id="home"
              onClick={() => changeComponent('home')}
            >
              <img src={imgHome} className="img-fluid" alt="Inicio " />
              <br></br>
              <span className="fuente">Inicio</span>
            </div>
          )}
          {user === 'tableauHouseData' ? (
            <div
              className="col-md-2 col-xs-2 col-lg-2 button-img-activo"
              id="house-data"
            >
              <img
                src={imgPeopleHouseActive}
                className="img-fluid"
                alt="Datos del Hogar "
              />
              <br></br>
              <span className="fuente">Datos del hogar</span>
            </div>
          ) : (
            <div
              className="col-md-2 col-xs-2 col-lg-2 button-img"
              id="house-data"
              onClick={() => changeComponent('tableauHouseData')}
            >
              <img
                src={imgPeopleHouse}
                className="img-fluid"
                alt="Datos del Hogar "
              />
              <br></br>
              <span className="fuente">Datos del hogar</span>
            </div>
          )}
          {user === 'tableauStoplightData' ? (
            <div
              className="col-md-2 col-xs-2 col-lg-2 button-img-activo"
              id="stoplight-data"
            >
              <img
                src={imgStopLightActive}
                className="img-fluid"
                alt="Semáforo General "
              />
              <br></br>
              <span className="fuente">Semafóro General</span>
            </div>
          ) : (
            <div
              className="col-md-2 col-xs-2 col-lg-2 button-img"
              id="stoplight-data"
              onClick={() => changeComponent('tableauStoplightData')}
            >
              <img
                src={imgStopLight}
                className="img-fluid"
                alt="Semáforo General "
              />
              <br></br>
              <span className="fuente">Semáforo General</span>
            </div>
          )}
        </div>
      </div>

      <div className="row" id="myComponent">
        <hr className="linea"></hr>
        <DynamicComponent user={user} />
      </div>
    </div>
  );
};

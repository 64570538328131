import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { DonutChart } from '../donut-chart/DonutChart';
import { ReactComponent as FamilyIcon } from './../../../images/family-svgrepo-com.svg';
import { ReactComponent as TrafficIcon } from './../../../images/traffic-light-svgrepo-com.svg';
import { ReactComponent as PeopleIcon } from './../../../images/people-svgrepo-com.svg';
import { ReactComponent as HouseIcon } from './../../../images/house-svgrepo-com.svg';

import { Spinner } from '../../spinner/Spinner';
import { COUNTRIES, numberWithCommas } from '../../../helpers/utils';
import { useQuery } from 'react-query';
import axios from 'axios';
import './Perspective.css';

/**
 * @param {number} from
 * @param {number} to
 */
function generateYearsRange(from, to) {
  /**
   * @type {number[]}
   */
  const years = [];
  for (let i = from; i <= to; i++) {
    years.push(i);
  }
  return years;
}

/**
 * @param {string} text
 */
function capitalize(text) {
  const words = text.split(' ');
  const newWords = words.map(
    element =>
      element.charAt(0).toUpperCase() + element.substring(1).toLowerCase(),
  );

  return newWords.join(' ');
}

const CURRENT_YEAR = new Date().getUTCFullYear();

export function Perspective() {
  const [filter, setFilter] = useState({
    /**
     * @type {string | null}
     */
    country: null,
    fromYear: null,
    toYear: null,
  });

  const surveysCount = useQuery({
    queryKey: ['surveysCount', filter],
    queryFn: async () =>
      await axios.post('/v1/stoplight/surveys', {
        country: filter.country,
        from_year: filter.fromYear,
        to_year: filter.toYear,
      }),
    select: data => data.data.total_surveys,
  });

  const familiesCount = useQuery({
    queryKey: ['familiesCount', filter],
    queryFn: async () =>
      await axios.post('/v1/stoplight/families', {
        country: filter.country,
        from_year: filter.fromYear,
        to_year: filter.toYear,
      }),
    select: data => data.data.total_families,
  });

  const peopleCount = useQuery({
    queryKey: ['peopleCount', filter],
    queryFn: async () =>
      await axios.post('/v1/stoplight/people', {
        country: filter.country,
        from_year: filter.fromYear,
        to_year: filter.toYear,
      }),
    select: data => data.data.total_people,
  });

  const overview = useQuery({
    queryKey: ['overview', filter],
    queryFn: async () =>
      await axios.post('/v1/stoplight/overview', {
        country: filter.country,
        from_year: filter.fromYear,
        to_year: filter.toYear,
      }),
    select: data => data.data,
  });

  const years = generateYearsRange(2011, CURRENT_YEAR);

  return (
    <div style={{ background: '#f3f4f6' }}>
      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-2 animate__animated animate__fadeInLeft">
            <h4 className="title-green-h4 text-center mb-5">
              Perspectiva general del Semáforo
            </h4>
            <ul className="perspective-result-1 mt-3">
              {!surveysCount.isLoading && (
                <li>
                  <span className="me-4">
                    <TrafficIcon className="perspective-result-1-icon" />
                  </span>
                  {numberWithCommas(surveysCount.data)} Semáforos
                </li>
              )}
              {!familiesCount.isLoading && (
                <li>
                  <span className="me-4">
                    <HouseIcon className="perspective-result-1-icon" />
                  </span>
                  {numberWithCommas(familiesCount.data)} familias
                </li>
              )}
              {!peopleCount.isLoading && (
                <li>
                  <span className="me-4">
                    <PeopleIcon className="perspective-result-1-icon" />
                  </span>
                  {numberWithCommas(peopleCount.data)} personas
                </li>
              )}
              {peopleCount.data && familiesCount.data && (
                <li>
                  <span className="me-4">
                    <FamilyIcon className="perspective-result-1-icon" />
                  </span>
                  {(peopleCount.data / familiesCount.data).toFixed(1)} promedio
                  de miembros
                </li>
              )}
            </ul>
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-6">
            <h4 className="title-green-h4 text-center">
              Cifras por nivel de pobreza
            </h4>
            {overview.isLoading ? (
              <div className="mt-5">
                <Spinner />
              </div>
            ) : (
              <div className="mt-5  d-flex justify-content-center">
                {!overview.data ? (
                  <div>
                    <h3 className="no-data"> Sin información</h3>
                    <h6 className="text-center">Pruebe con otros parámetros</h6>
                  </div>
                ) : (
                  <DonutChart
                    totalRed={
                      overview.data.find(item => item.stoplight_value === '1')
                        ?.count ?? 0
                    }
                    totalYellow={
                      overview.data.find(item => item.stoplight_value === '2')
                        ?.count ?? 0
                    }
                    totalGreen={
                      overview.data.find(item => item.stoplight_value === '3')
                        ?.count ?? 0
                    }
                    totalSkipped={
                      overview.data.find(item => item.stoplight_value === '0')
                        ?.count ?? 0
                    }
                    totalDoesNotApply={
                      overview.data.find(item => item.stoplight_value === '9')
                        ?.count ?? 0
                    }
                  />
                )}
              </div>
            )}
          </div>
          <div className="col-xl-4 col-lg-4  col-sm-10  animate__animated animate__fadeInRight">
            <form className="perspective-form">
              <div className="form-group">
                <label>Países de implementación</label>
                <select
                  className="form-select select-white"
                  defaultValue={filter.country}
                  disabled={
                    surveysCount.isLoading ||
                    familiesCount.isLoading ||
                    peopleCount.isLoading ||
                    overview.isLoading
                  }
                  onChange={e => {
                    if (e.target.value) {
                      const value =
                        e.target.value === 'Todos' ? null : e.target.value;

                      setFilter({
                        ...filter,
                        country: value,
                      });
                    }
                  }}
                >
                  <option value={null}>Todos</option>
                  {COUNTRIES.map(country => (
                    <option key={country} value={country}>
                      {capitalize(country)}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="mt-3">Año de implementación</label>
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <div className="input-group">
                      <span className="input-group-text input-icon-white">
                        <FontAwesomeIcon
                          icon={faCalendar}
                          className="mx-1 text-gray-600"
                        />
                      </span>
                      <select
                        className="form-select select-white"
                        defaultValue={filter.fromYear}
                        disabled={
                          surveysCount.isLoading ||
                          familiesCount.isLoading ||
                          peopleCount.isLoading ||
                          overview.isLoading
                        }
                        onChange={event => {
                          const value =
                            event.target.value === 'Desde'
                              ? null
                              : event.target.value;

                          setFilter({ ...filter, fromYear: value });
                        }}
                      >
                        <option value={null}>Desde</option>
                        {!years
                          ? []
                          : years.map(year => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <span className="input-group-text input-icon-white">
                        <FontAwesomeIcon
                          icon={faCalendar}
                          className="mx-1 text-gray-600"
                        />
                      </span>
                      <select
                        className="form-select select-white"
                        defaultValue={filter.toYear}
                        disabled={
                          surveysCount.isLoading ||
                          familiesCount.isLoading ||
                          peopleCount.isLoading ||
                          overview.isLoading
                        }
                        onChange={event => {
                          const value =
                            event.target.value === 'Hasta'
                              ? null
                              : event.target.value;

                          setFilter({ ...filter, toYear: value });
                        }}
                      >
                        <option value={null}>Hasta</option>
                        {!years
                          ? []
                          : years.map(year => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
